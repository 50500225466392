
import classeAPI from "@/services/classe/httpclasse";
import axios from "axios";

export default {
  name: "classe",
  data() {
    return {
        // receive methods listItens
      itemsList: [],
  
        //all itens to search
      allItems: [],
      
      // body classe
      AddItems: {
        //"contaId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "id": "d2c8c819-5d1c-492d-af4d-c08d78571261",
        "contaId": "00000000-0000-0000-0000-000000000000",
        "integracaoId": null,
        "descricao": "",
        "validadeDiasFarmaciaPopular": "",        
      },
      //search
      searchItems: '',

      //page numbers
      pgNumb: '',
      // about pages
      totalPages: '',
      currentPage: 1,
      itensQtd: '',    
    };
  },
  components: {
  //  Methods,
  },

  methods: {

    previousPage(page) {
      if (page === this.currentPage && (page <= this.totalPages && page > 1)) {
        this.currentPage -= 1
        this.listItens()
        return
      }

      this.currentPage = page;
      this.listItens()
    },
    nextPage(page) {
      if (page === this.currentPage && (page < this.totalPages && page >= 1)) {
        this.currentPage += 1
        this.listItens()
        return
      }

      this.currentPage = page;
      this.listItens()
    },
    //Get list Mehtods and pages from Api
    listItens() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/classe/obter-grid/${this.currentPage}`)
        .then(res => {
          this.itemsList = res.data.itens;
          this.totalPages = res.data.totalDePaginas;
        
        });
    },

    //Send itens values to api - button Salvar
    saveItens() {
      classeAPI.classeaddNew(this.AddItems)
        .then(res => {
        
        alert("salvo com sucesso", res),
        this.listItens(); // update item on screen 
        this.AddItems = {}; //clear fields
      });
    },
    //To edit values itens - button Editar
    editAction(AddItems){
      this.AddItems = AddItems
    },
    
    // Send edited values to APi
    updateItens() {
     classeAPI.classeUpdate(this.AddItems)
     .then(res => {
       alert("alterado com sucesso", res),
       this.listItens(); // update item on screen 
       this.AddItems = {}; //clear
      });
    },
    
    // Delet Itens from Api - button Apagar
    deletItens(AddItems){
      if(confirm('Deseja excluir?')){
        axios.post(
          `${process.env.VUE_APP_BASE_URL}/classe/remover/`+AddItems.id,  
        )
        .then((res) =>{
          alert("Removido com sucesso", res),
          this.listItens();
          this.errors = []
        })
      }
    },
    // Filter
    allDescrition(){
      let i = 1
      let allPages = '3'
      let arr = []
      
      //console.log('Total pages', allPages)   
      
      for (i = 1; i <= allPages; i++) {
        arr.push(axios.get(`${process.env.VUE_APP_BASE_URL}/classe/obter-grid/`+i ))
        Promise.all(arr).then((res) =>{
         arr = res 
          })
        }
    },

    
  },//End Methods
  mounted() {
    //Start with itens list
     this.listItens(); 
     this.$store.dispatch('classe/classeSearch')

    //this.allDescrition();

   // console.log('Todos itens',  this.allItems)    
  },

  computed: {
    //Function filter Search
    itensFiltered(){
      let values = []
      values = this.itemsList.filter((description) => {
        return description.descricao.toLowerCase().indexOf(this.searchItems.toLowerCase()) > -1
      });
      return values;
    },
    
  
    itensFilclasse(){
      const { classe } = this.$store.state.classe
      let valuesclasse = []
      valuesclasse = classe.filter((description) => {
        return description.descricao.toLowerCase().indexOf(this.searchItems.toLowerCase()) > -1});
      return valuesclasse;
    }, 

 


   
  },
};



 